import firebaseApp from "./firebase.service";
import "firebase/firestore";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  getDoc,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";

import User from "@/interfaces/registration.interface";
import { getAuth } from "firebase/auth";
const db = getFirestore(firebaseApp);

class RegistrationService {
  async getAll() {
    const querySnapshot = await getDocs(collection(db, "registrations"));

    return querySnapshot.docs.map((userDoc) => {
      return userDoc.data();
    });
  }

  async getOne(id: string) {
    const docRef = doc(db, "registrations", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    return false;
  }
  async create(user: User) {
    const auth = getAuth();
    // connectAuthEmulator(auth, "http://127.0.0.1:9099");
    const currentUser = auth.currentUser;
    if (currentUser) {
      user.authorId = currentUser.uid;
      try {
        await setDoc(doc(db, "registrations", currentUser.uid), user);
      } catch (error) {
        console.log("Db error: ");

        return false;
      }
      return true;
    }
    return false;
  }
  async update(id: string, user: User) {
    const docRef = doc(db, "registrations", id);
    //TODO: this is hot garbage, figure out how to pass an object to the update method
    try {
      await updateDoc(docRef, {
        firstName: user.firstName||"",
        lastName: user.lastName||"",
        phone: user.phone||"",
        address: user.address||"",
        ushpa: user.ushpa || "",
        trackerAddress: user.trackerAddress|| "",
        trackingConfirmation: user.trackingConfirmation || false,
        trackerLink: user.trackerLink ||"",
        gliderMake: user.gliderMake|| "",
        gliderModel: user.gliderModel|| "",
        gliderENRating: user.gliderENRating|| "",
        gliderPrimaryColor: user.gliderPrimaryColor || "",
        gliderSecondaryColor: user.gliderSecondaryColor|| "",
        reserveColor: user.reserveColor|| "",
        emergencyContactName: user.emergencyContactName|| "",
        emergencyContactPhone: user.phone|| "",
        campingType: user.campingType|| "",
        registrationType: user.registrationType|| "",
        paymentId: user.paymentId || "",
        paymentStatus: user.paymentStatus || "",
        paymentDetails: user.paymentDetails || "",
        mealPass: user.mealPass || 0,
        mealVegan: user.mealVegan || 0,
        guests: user.guests || 0,
        thursdayCamping: user.thursdayCamping || false,
      });
    } catch (error) {
      console.log("Db error:", error);
      return false;
    }
    return true;
  }

  async delete(id: string) {
    await deleteDoc(doc(db, "registrations", id));
    return false;
  }
}
export default new RegistrationService();
