
import { ref, onMounted, defineComponent, computed } from "vue";
import { Auth, getAuth, onAuthStateChanged } from "firebase/auth";
import { useRouter } from "vue-router";
import ProfileInfo from "@/components/ProfileInfo.vue";
import SignIn from "@/components/SignIn.vue";
import RegistrationService from "@/services/registration.service";

export default defineComponent({
  name: "Profile",
  components: {
    ProfileInfo,
    SignIn,
  },

  setup() {
    const router = useRouter();
    const loggedIn = ref(false);
    let auth: Auth;

    onMounted(() => {
      auth = getAuth();
      // connectAuthEmulator(auth, "http://127.0.0.1:9099");
      onAuthStateChanged(auth, (user) => {
        if (user) {
          loggedIn.value = true;
          //user just logged in, update the timeline
          updateTimeline();
        } else {
          loggedIn.value = false;
        }
      });
    });

    const logOut = () => {
      auth.signOut();
      router.push("/");
    };

    const updateTimeline = () => {
      if (auth.currentUser) {
        setTimelineEvent(0);
        RegistrationService.getOne(auth.currentUser.uid).then(
          (registrationInfo) => {
            if (registrationInfo) {
              setTimelineEvent(1);
              if (
                registrationInfo.paymentId &&
                registrationInfo.paymentId != ""
              ) {
                setTimelineEvent(2);
              }
              if (
                registrationInfo.paymentStatus &&
                registrationInfo.paymentStatus != ""
              ) {
                setTimelineEvent(3);
              }
            }
          }
        );
      } else {
        for (let i = 0; i < registrationTimeline.value.length; i++) {
          clearTimelineEvent(i);
        }
      }
    };
    const setTimelineEvent = (i: number) => {
      registrationTimeline.value[i].icon = "pi pi-check-circle";
      registrationTimeline.value[i].color = "#60c700";
    };
    const clearTimelineEvent = (i: number) => {
      registrationTimeline.value[i].icon = "";
      registrationTimeline.value[i].color = "";
    };
    const registrationTimeline = ref([
      {
        status: "Account Created",
        date: "",
        icon: "",
        color: "",
      },
      {
        status: "Information Saved",
        date: "",
        icon: "",
        color: "",
      },
      {
        status: "Payment Received",
        date: "",
        icon: "",
        color: "",
      },
      {
        status: "Registration Complete",
        date: "",
        icon: "",
        color: "",
      },
    ]);

    const registrationProgress = computed(() => {
      // compute current users progress

      return "Account Created";
    });
    return {
      loggedIn,
      logOut,
      registrationTimeline,
      registrationProgress,
      updateTimeline,
    };
  },
});
