
import { ref, reactive, defineComponent, onMounted, watchEffect } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric, sameAs } from "@vuelidate/validators";
import {
  loadScript,
  OrderResponseBody,
  PayPalScriptDataAttributes,
  PayPalScriptOptions,
} from "@paypal/paypal-js";
import RegistrationService from "@/services/registration.service";
import Registration from "@/interfaces/registration.interface";
import { getAuth } from "firebase/auth";

export default defineComponent({
  components: {},
  setup(props, context) {
    const existingUser = ref(false);
    const submitted = ref(false);
    const showPayment = ref(false);
    const auth = getAuth();
    // connectAuthEmulator(auth, "http://127.0.0.1:9099");
    let paid = ref(false);

    let currentUser = auth.currentUser;
    const hostEmails = ["anderson.chasew@gmail.com", "brian.greeson@gmail.com", "lukerome90@gmail.com", "marshalllukas.hill@gmail.com", "tommahr32@gmail.com"]
    const registrationInfo = reactive({} as Registration);
    const disablePayment = false;

    const rules = {
      firstName: { required },
      lastName: { required },
      phone: { required, numeric },
      address: { required },
      ushpa: { required },
      trackerAddress: { required },
      trackerLink: { required },
      ognAddress: {},
      gliderMake: { required },
      gliderModel: { required },
      gliderENRating: { required },
      gliderPrimaryColor: { required },
      gliderSecondaryColor: {},
      reserveColor: {},
      trackingConfirmation: { required, sameAsRawValue: sameAs(true) },
      emergencyContactName: { required },
      emergencyContactPhone: { required },
      guests: {},
      campingType: { required },
      guestActivities: {},
      registrationType: { required },
      flyingHistory: {},
      additionalPilotInfo: {},
      supporterContactInfo: {},
      mealPass: {},
      mealVegan: {},
      thursdayCamping: {},

    };
    const ratingOptions = [
      { name: "EN-A", code: "A" },
      { name: "EN-B", code: "B" },
      { name: "EN-C", code: "C" },
      { name: "EN-D", code: "D" },
    ];
    const campingOptions = [
      { name: "Tent / Car Camping", code: "tent" },
      { name: "Sprinter / RV", code: "rv" },
      { name: "Ranch House Rental", code: "ranch" },
      { name: "Other", code: "other" },
    ];
    const registrationOptions = [
      { name: "FunFly: $175", code: "funfly" },
      // { name: "Daily Comp $180", code: "daily" },
      { name: "Bivy Comp $200", code: "bivy" },
    ];

    onMounted(() => {
      currentUser = auth.currentUser;
      if (currentUser) {
        RegistrationService.getOne(currentUser.uid).then((registration) => {
          if (registration) {
            existingUser.value = true;
            Object.assign(registrationInfo, registration);
            if (registrationInfo.paymentStatus === "COMPLETED") {
              paid.value = true;
            }
          }
        });
      }
    });

    const handleSubmit = async (isFormValid: boolean) => {
      submitted.value = true;
      if (currentUser) {
        if (isFormValid) {
          let result = false;
          if (!existingUser.value) {
            result = await RegistrationService.create(registrationInfo);
          } else {
            if (v$.value.$anyDirty) {
              result = await RegistrationService.update(
                currentUser.uid,
                registrationInfo
              );
            } else {
              result = true;
            }
          }
          if (result) {
            alert(paid.value ? "Information updated" : "Information saved, please proceed to payment");
            if (!registrationInfo.paymentId) {
              showPayment.value = true;
              loadPayPal();
            }
          }
        }
      }
    };
    const v$ = useVuelidate(rules, registrationInfo);

    const loadPayPal = () => {
      if (disablePayment) {
        return;
      }
      loadScript({
        "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
      })
        .then((paypal: any) => {
          paypal
            .Buttons({
              createOrder: function (
                data: PayPalScriptDataAttributes,
                actions: PayPalScriptOptions
              ) {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: amountDue.value,
                      },
                    },
                  ],
                });
              },
              onApprove: function (
                data: PayPalScriptDataAttributes,
                actions: PayPalScriptOptions
              ) {
                // This function captures the funds from the transaction.
                return actions.order
                  .capture()
                  .then(function (details: OrderResponseBody) {
                    // This function shows a transaction success message to your buyer.

                    if (currentUser) {
                      registrationInfo.paymentId = details.id;
                      registrationInfo.paymentStatus = details.status;
                      RegistrationService.update(
                        currentUser.uid,
                        registrationInfo
                      );

                    }
                    context.emit("registrationUpdate", registrationInfo);
                    paid.value = true;
                  });
              },
            })
            .render("#paypalButton")
            .catch(() => {
              console.error("failed to render the PayPal Buttons");
            });
        })
        .catch(() => {
          console.error("failed to load the PayPal JS SDK script");
        });
    };

    const amountDue = ref(0);
    watchEffect(() => {
      let amount = 0;
      switch (registrationInfo.registrationType) {
        case "funfly":
          amount = 175;
          break;
        case "bivy":
          amount = 200;
          break;
        default:
          amount = 175;
          break;

      }
      if (registrationInfo.guests) amount += registrationInfo.guests * 30
      if (registrationInfo.mealPass) amount += 75;
      if (registrationInfo.thursdayCamping) amount += 25
      if (currentUser?.email && hostEmails.includes(currentUser.email))
        amount = amount / 10
      amountDue.value = amount
    });

    return {
      paid,
      amountDue,
      showPayment,
      submitted,
      registrationInfo,
      v$,
      handleSubmit,
      campingOptions,
      ratingOptions,
      registrationOptions,
    };
  },
});
